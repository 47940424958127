<template>
  <div class="row g-2">
    <div class="col-sm-4">
      <div>
        <input
          v-model="zip"
          class="form-control"
          type="text"
          :class="{ 'is-invalid': !!invalid }"
          autocomplete="off"
          :placeholder="$t('user.zip')"
          @keydown="showList = true"
          @blur="blurred = true"
        />
        <div class="invalid-feedback">
          {{ invalid }}
        </div>
        <div v-if="cities.length" class="zip-list">
          <p v-for="c in cities" :key="c.id" @click="selectCity(c)">
            <strong>{{ c.npa }}</strong> {{ c.name }} <em>({{ c.canton }})</em>
          </p>
        </div>
        <label v-if="label">{{ label }}</label>
      </div>
    </div>
    <div class="col">
      <div>
        <input
          v-model="cityLocal"
          class="form-control"
          type="text"
          :placeholder="$t('user.city')"
          readonly
        />
      </div>
    </div>
  </div>
</template>

<script>
import Rules from '@/mixins/Rules'
import lodash from 'lodash'

export default {
  name: 'UserZipField',
  mixins: [Rules],

  props: {
    value: { type: [String, Number] },
    city: { type: String },
    label: { type: String }
  },

  data: () => ({
    cities: [],
    showList: false,
    loading: false
  }),

  computed: {
    zip: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    cityLocal: {
      get() {
        return this.city
      },
      set(v) {
        this.$emit('update:city', v)
      }
    }
  },

  watch: {
    zip: 'debouceSearchCities'
  },

  methods: {

    debouceSearchCities: lodash.debounce(function(zip) {
      if (!this.showList || !zip || zip.length < 3) {
        return
      }
      this.loading = true
      this.cities = []
      return this.$store.dispatch('user/searchCitiesByZip', { zip })
        .then(cities => (this.cities = cities))
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }, 10),

    selectCity(city) {
      this.zip = city.npa
      this.cityLocal = city.name
      this.cities = []
      this.showList = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.zip-list {
  border: 1px solid #ccc;
  padding: 10px 0;
  min-width: 50%;
  font-size: 90%;
  position: absolute;
  background-color: #fff;
  z-index: 90;
  max-height: 200px;
  overflow-y: auto;

  p {
    margin: 0;
    padding: 4px 10px;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      background-color: #ddd;
    }
  }
}
</style>
