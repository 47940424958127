<template>
  <div>
    <input
      v-model="street"
      class="form-control"
      type="text"
      name="street"
      :class="{ 'is-invalid': !!invalid }"
      :placeholder="$t('user.street')"
      @blur="blurred = true"
    />
    <div class="invalid-feedback">
      {{ invalid }}
    </div>
  </div>
</template>

<script>
import Rules from '@/mixins/Rules'

export default {
  name: 'UserStreetField',
  mixins: [Rules],

  props: {
    value: { type: String }
  },

  computed: {
    street: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  }
}
</script>
