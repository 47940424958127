<template>
  <div>
    <select
      v-model="country"
      class="form-select"
      :class="{ 'is-invalid': !!invalid }"
      aria-label=""
      :placeholder="$t('user.country')"
      @blur="blurred = true"
    >
      <option
        v-for="c in countries"
        :key="c"
        :value="c"
        :selected="c === country"
      >
        {{ $t(`countries.${c.toLowerCase()}`) }}
      </option>
    </select>
    <div class="invalid-feedback">
      {{ invalid }}
    </div>
    <label v-if="label">{{ label }}</label>
  </div>
</template>

<script>
import Rules from '@/mixins/Rules'

export default {
  name: 'UserCourtesyField',
  mixins: [Rules],

  props: {
    value: { type: String },
    label: { type: String },
    pick: { type: Array, default: () => [] }
  },

  data: () => ({
    countries: []
  }),

  computed: {
    country: {
      get() {
        return this.value && this.value.toLowerCase()
      },
      set(v) {
        this.$emit('input', v && v.toUpperCase())
      }
    }
  },

  mounted() {
    // fetch available countries
    const countries = Object.keys(this.$i18n.t('countries', { returnObjects: true }))
    if (this.pick.length) {
      this.countries = countries.filter(c => this.pick.indexOf(c) !== -1)
    } else {
      this.countries = countries
    }
  }
}
</script>
