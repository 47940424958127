<template>
  <div>
    <input
      v-model="phone"
      class="form-control"
      type="text"
      placeholder="079 200 00 00"
      :class="{ 'is-invalid': !!invalid }"
      @blur="blurred = true"
    />
    <div class="invalid-feedback">
      {{ invalid }}
    </div>
    <label v-if="label">{{ label }}</label>
  </div>
</template>

<script>
import Rules from '@/mixins/Rules'

export default {
  name: 'UserPhoneField',
  mixins: [Rules],

  props: {
    value: { type: String },
    label: { type: String }
  },

  computed: {
    phone: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  }
}
</script>
