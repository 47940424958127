<template>
  <div>
    <select
      v-model="courtesy"
      class="form-select"
      :class="{ 'is-invalid': !!invalid }"
      aria-label=""
      :placeholder="$t('user.courtesy')"
      @blur="blurred = true"
    >
      <option
        v-for="c in courtesies"
        :key="c"
        :value="c"
        :selected="c === courtesy"
      >
        {{ $t(`courtesies.${c}`) }}
      </option>
    </select>
    <div class="invalid-feedback">
      {{ invalid }}
    </div>
  </div>
</template>

<script>
import Rules from '@/mixins/Rules'

export default {
  name: 'UserCourtesyField',
  mixins: [Rules],

  props: {
    value: { type: String }
  },

  data: () => ({
    courtesies: ['Mme', 'M.']
  }),

  computed: {
    courtesy: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  }
}
</script>
